import { render, staticRenderFns } from "./userJournal.vue?vue&type=template&id=2d720520&scoped=true"
import script from "./userJournal.vue?vue&type=script&lang=js"
export * from "./userJournal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d720520",
  null
  
)

export default component.exports