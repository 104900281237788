<template>
  <div class="contatain">
    <div class="content-wrap">
      <el-card class="row2-card">
        <el-table
          border
          :data="userJournal"
          :header-cell-style="thStyle"
          :cell-style="cellStyle"
          v-loading="loading"
          max-height="700"
        >
          <el-table-column width="150" prop="address" label="来源地">
          </el-table-column>
          <el-table-column
            prop="ip"
            label="来源ip"
            width="150"
          ></el-table-column>
          <el-table-column width="120" label="用户名" prop="name">
          </el-table-column>
          <el-table-column
            width="200"
            prop="url"
            label="访问地址"
          ></el-table-column>
          <el-table-column
            width="200"
            prop="stayTime"
            label="停留时间"
          >
            <template slot-scope="scope">
              {{formatSecondsTable(scope.row.stayTime)}}
            </template>
          </el-table-column>
          <el-table-column
            width="200"
            prop="previousUrl"
            label="上次访问地址"
          ></el-table-column>
          <el-table-column prop="type" width="150" label="请求操作"></el-table-column>
          <el-table-column width="200" label="访问时间">
            <template slot-scope="scope">
              {{ transTime(scope.row.createTime) }}
            </template>
          </el-table-column>
          <el-table-column
            width="300"
            prop="browser"
            label="浏览器标识"
          ></el-table-column>
          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <el-button
                @click="openDialog('showLog', scope.row)"
                type="text"
                size="small"
                >查看</el-button
              >
              <el-button
                type="text"
                size="mini"
                @click="deleteLog(scope.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          layout="prev, pager, next, sizes"
          :total="count"
          :page-size="params.limit"
          :current-page="params.page"
          :page-sizes="[5, 10, 15, 20]"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        >
        </el-pagination>
      </el-card>
      <showUserVisit ref="showLog" :editForm="editForm" />
    </div>
  </div>
</template>
<script>
import { timestampToDate, formatSeconds } from "@tools";
import showUserVisit from './components/showUserVisit'
export default {
  components: {
    showUserVisit
  },
  data() {
    return {
      loading: false,
      params: {
        limit: 10,
        page: 1,
      },
      count: 0,
      editForm: {},
      userJournal: [],
    };
  },
  async created() {
    // this.getDictTypeDataList();
    this.getUserJournal();
  },
  mounted() {},
  methods: {
    async getUserJournal() {
      this.loading = true;
      const { data, count } = await this.$api.log
        .getUserVisit(this.params)
        .finally(() => {
          this.loading = false;
        });
      this.userJournal = data;
      this.count = count;
    },
    handleSizeChange(size) {
      this.params.limit = size;
      this.getUserJournal();
    },
    handlePageChange(page) {
      this.params.page = page;
      this.getUserJournal();
    },
    transTime(time) {
      return timestampToDate(time);
    },
    openDialog(ref, form) {
      if (form) {
        this.editForm = form;
      } else {
        this.editForm = {};
      }
      this.$refs[ref].showDialog = true;
    },
    deleteLog(id) {
      this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await this.$api.log.deleteUserVisit(id);
          this.getUserJournal();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    toDictData(type) {
      this.$router.push({ path: "/home/dictData", query: { type } });
    },
    thStyle() {
      return {
        background: "rgba(197, 192, 255, 0.1)",
        color: "#000",
        textAlign: "center",
      };
    },
    cellStyle() {
      return {
        fontWeight: "500",
        color: "#000",
        textAlign: "center",
      };
    },
    formatSecondsTable(time) {
      return formatSeconds(time)
    }
  },
  watch: {
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
</style>