<template>
  <el-dialog width="25%" :title="title" :visible.sync="showDialog">
    <el-form :model="form" label-width="110px" ref="aa">
      <el-form-item label="来源地"> {{ editForm.address }} </el-form-item>
      <el-form-item label="来源ip"> {{ editForm.ip }} </el-form-item>
      <el-form-item label="用户名"> {{ editForm.name }} </el-form-item>
      <el-form-item label="访问地址"> {{ editForm.url }} </el-form-item>
      <el-form-item label="停留时间"> {{ formatSeconds(editForm.stayTime) }} </el-form-item>
      <el-form-item label="上次访问地址">
        {{ editForm.previousUrl }}
      </el-form-item>
      <el-form-item label="请求操作"> {{ editForm.type }} </el-form-item>
      <el-form-item label="访问时间"> {{ timestampToDate(editForm.createTime) }} </el-form-item>
      <el-form-item label="浏览器标识"> {{ editForm.browser }} </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { timestampToDate, formatSeconds } from "@tools";
export default {
  name: "showUserVisit",
  props: {
    editForm: {
      default: () => {},
    },
  },
  mounted() {},
  data() {
    return {
      title: "查看记录",
      showDialog: false,
    };
  },
  methods: {
      timestampToDate,
      formatSeconds
  },
  watch: {},
};
</script>